<template>
  <div class="container page">
    <div class="text-center">
      <img class="img-fluid" src="/maps.gif" loading="lazy" alt="Check out my modern maps for Cthulhu RPG"/>
    </div>

    <div class="content">
      <h2>Architects of Madness: Explore Cthulhu Architect's Haunting Maps!</h2>
      <p>Immerse yourself in the chilling world of cosmic horror with our meticulously designed modern maps for your RPG. Picture a world where ancient cosmic entities threaten to break through the veil of reality, where investigators must unravel sinister conspiracies and battle their own crumbling sanity. Our maps transport you to the shadowed corners of a modern setting, where eldritch horrors lurk just beneath the surface. With attention to detail and a focus on atmospheric storytelling, our maps serve as gateways to nightmarish encounters and harrowing investigations. Prepare to experience the true essence of Cthulhu Mythos with our hauntingly immersive maps!</p>

      <div class="text-center">
          <a href="https://cthulhuarchitect.com/maps" class="btn btn-lg btn-primary" target="_blank">Take me to the maps</a>
      </div>

      <div class="text-center">
      <iframe width="100%" height="600" src="https://www.youtube.com/embed/wa2JEMyox8M?si=pkS-fXmPeitTBose" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
    name: "Home"
}
</script>

<style lang="scss" scoped>
  .page {
    font-size:20px;
    line-height:28px;
    background:#fff;
    margin-top:20px;
    margin-bottom:20px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
  }

  h2 {
    margin-bottom: 20px;
  }

  .btn-primary {
    margin-top:30px;
    margin-bottom:50px;
    font-size: 26px;
    padding: 20px 40px;
    font-weight:bold;
  }

  .content {
    padding: 50px;
  }
</style>
