<template>
  <div class="container page">
    <div class="content">

      <div class="text-center">
        <h2>Get the handout generator module for FoundryVTT!</h2>
        <p>Create handouts quickly within your foundry world. Simple drag & drop interface.</p>
        
        <a href="https://cthulhuarchitect.com/foundry-vtt-modules/handout-generator-foundry-module/" target="_blank">
          <img class="img-fluid" src="/handout-generator.jpg" loading="lazy" alt="Check out the handout generator foundry module"/>
        </a>
      </div>

      <div class="text-center">
          <a href="https://cthulhuarchitect.com/foundry-vtt-modules/handout-generator-foundry-module/" class="btn btn-lg btn-primary" target="_blank">Tell me more</a>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
    name: "Foundry"
}
</script>

<style lang="scss" scoped>
.img-fluid {
  margin-top:20px;
}

  .page {
    font-size:20px;
    line-height:28px;
    background:#fff;
    margin-top:20px;
    margin-bottom:20px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
  }

  h2 {
    margin-bottom: 20px;
  }

  .btn-primary {
    margin-top:30px;
    margin-bottom:50px;
    font-size: 26px;
    padding: 20px 40px;
    font-weight:bold;
  }

  .content {
    padding: 50px;
  }
</style>
